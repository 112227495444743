.Row {
    display: table;
    width: auto;
    /*Optional*/
    table-layout: fixed;
    /*Optional*/
    border-spacing: 10px;
    /*Optional*/
    margin-bottom: 10px;
}

.Column {
    display: table-cell;
    background-color: clear;
    /*Optional*/
}