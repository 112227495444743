*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.not-scroll-body::-moz-scrollbar {
  display: none; 
}

p {
  margin-bottom: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p, span, h1,h2,h3,h4,h5,h6, button{
  user-select: none;
}

:root {
  --color-background: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}